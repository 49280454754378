.BuiltInFeature {
  img {
    width: 100%;
    // Chrome
    height: auto;
    // Firefox
    height: -moz-max-content;
    // Safari
    height: intrinsic;
  }
}
