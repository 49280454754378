// Responsive width
@media (min-width: 576px) {
  .w-sm-50 {
    width: 50% !important;
  }
}

@media (min-width: 768px) {
  .w-md-50 {
    width: 50% !important;
  }
}

// "Footer" website
.mb-7 {
  margin-bottom: 7em;
}

// Responsive font-size
@media (max-width: 576px) {
  .fs-xs-50 {
    font-size: 100% !important;
  }
}
