.Header {
  svg {
    height: auto;
    min-width: 300px;

    & > circle {
      --darkreader-inline-fill: #ffffff !important;
    }
  }
}
